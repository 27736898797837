var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white px-1 rounded-lg"},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Transfer Items'))+" ")]),_c('scan',{attrs:{"asset-item-serial-numbers":_vm.serialNumbers},on:{"getValue":_vm.handleScannedValue}}),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Order RO-'))+"1024 ")]),_c('l-table-list-collector',{ref:("lTableRef-" + (1)),attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"isSearchable":false,"has-footer":false,"fetched-data":_vm.assetItems,"row-active":{
        filedKey: 'pick_at',
        classes: 'highlightRow'
      },"show-empty-list-title":""},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"padding-left":"16px"}},[_c('b-form-checkbox',{staticClass:"_custom_checkbox"})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"_custom_checkbox",on:{"change":function($event){return _vm.handlePickUp(item)}}})],1)]}},{key:"cell(state)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('state-of-asset',{attrs:{"data":item,"size":"30","with-label":false,"styles":{ color: '#00B139', width: '20px', height: '20px' }}})],1)]}},{key:"cell(sku)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.product.sku)+" ")])]}},{key:"cell(name)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex nameCell"},[(item.icon)?_c('span',{staticClass:"badge badge-secondary border-0"},[_vm._v("C")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.product.name))])])])]}},{key:"cell(location)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.location.level_name)+" ")])]}},{key:"cell(asset_id)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center d-flex"},[_c('feather-icon',{staticClass:"lightIcon border-dotted featherIcon",attrs:{"icon":"LDangerIcon","size":"30"}}),_vm._v(" "+_vm._s(item.asset_id)+" ")],1)]}},{key:"cell(picked)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[(item.pick_at)?_c('feather-icon',{staticClass:"lightIcon featherIcon",staticStyle:{"color":"green"},attrs:{"icon":"LCheckIcon","size":"30"},on:{"click":function($event){return _vm.handleClick(item)}}}):_vm._e()],1)]}},{key:"cell(inspected)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('b-form-checkbox',{staticClass:"customCheckbox",on:{"change":function($event){return _vm.handleInspected(item)}}})],1)]}}])}),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Permanent Transfer'))+" ")]),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"isSearchable":false,"fetched-data":_vm.permanentItems,"show-empty-list-title":"","row-active":{
        filedKey: 'pick_at',
        classes: 'highlightRow'
      }},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"padding-left":"16px"}},[_c('b-form-checkbox',{staticClass:"_custom_checkbox"})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"_custom_checkbox",on:{"change":function($event){return _vm.handlePickUp(item)}}})],1)]}},{key:"cell(state)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('state-of-asset',{attrs:{"data":item,"size":"30","with-label":false,"styles":{ color: '#00B139', width: '20px', height: '20px' }}})],1)]}},{key:"cell(sku)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.product.sku)+" ")])]}},{key:"cell(name)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex nameCell"},[(item.icon)?_c('span',{staticClass:"badge badge-secondary border-0"},[_vm._v("C")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.product.name))])])])]}},{key:"cell(location)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.location.level_name)+" ")])]}},{key:"cell(asset_id)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center d-flex"},[_c('feather-icon',{staticClass:"lightIcon border-dotted featherIcon",attrs:{"icon":"LDangerIcon","size":"30"}}),_vm._v(" "+_vm._s(item.asset_id)+" ")],1)]}},{key:"cell(picked)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[(item.pick_at)?_c('feather-icon',{staticClass:"lightIcon featherIcon",staticStyle:{"color":"green"},attrs:{"icon":"LCheckIcon","size":"30"},on:{"click":function($event){return _vm.handleClick(item)}}}):_vm._e()],1)]}},{key:"cell(inspected)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('b-form-checkbox',{staticClass:"customCheckbox",on:{"change":function($event){return _vm.handleInspected(item)}}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }